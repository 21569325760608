import React from 'react';
import MyRoutes from './routes';
import "./App.css";

function App() {
  return (
    <div className="App ">
     <MyRoutes/>
    </div>
  );
}

export default App;
